import React from "react";

// External

// Internal
import Layout from "../components/layout";
import Seo from "../components/SEO";
import AroundTheWebSection from "../containers/around-the-web-section";
import ContactMeSection from "../containers/contact-me-section";

const ContactMePage = () => {
  return (
    <Layout>
      <Seo
        title="Contact Me"
        description="Work Query & Contact details for Parmeet Singh Asija"
      />
      <ContactMeSection />
      <AroundTheWebSection />
    </Layout>
  );
};

export default ContactMePage;
